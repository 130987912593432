import { z } from "zod";
import { GenericSize } from "./geom.ts";
import { weightFromG } from "./weight.ts";

type NumberOfBasePlatesDimension = number;

type NumberOfBasePlates = GenericSize<NumberOfBasePlatesDimension>;

const basePlateSizeSchema = z.number().int().positive();

type BaseplateSize = z.infer<typeof basePlateSizeSchema>;

const knownBasePlateSizes = [
	{
		size: 24,
		weight: weightFromG(70),
		abbreviation: "SB",
		numberOfBasePlatesPerPackaging: 9,
	},
	{
		size: 32,
		weight: weightFromG(121),
		abbreviation: "LB",
		numberOfBasePlatesPerPackaging: 8,
	},
];

const maxNumberOfBasePlates = 20;

function getKnownBasePlateSize(size: BaseplateSize) {
	return knownBasePlateSizes.find((s) => s.size === size);
}

const maxKnownBasePlateSize = Math.max(
	...knownBasePlateSizes.map((s) => s.size),
);

function basePlateSizeAbbreviation(size: BaseplateSize) {
	return getKnownBasePlateSize(size)?.abbreviation ?? size.toString();
}

export type { BaseplateSize, NumberOfBasePlates, NumberOfBasePlatesDimension };
export {
	basePlateSizeAbbreviation,
	basePlateSizeSchema,
	maxKnownBasePlateSize,
	getKnownBasePlateSize,
	maxNumberOfBasePlates,
};
