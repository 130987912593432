import {
	Palette,
	BaseplateSize,
	PricingModel,
	Scene,
	maxNumberOfBasePlates,
} from "@brickme/project-core/src";
import { GetPicToBrickCategoriesQuery } from "~/api/get-pic-to-brick-categories.ts";
import type {
	PictureFrame,
	HangingStrips,
	PictureAddOnVariant,
} from "~/model.d.ts";
import { OnceOffLoad, pendingOnceOffLoad } from "~/utils/loading.ts";
import { Country } from "~/frontend-common/localisation.ts";
import { ShopifyCollection } from "~/shopify/common.ts";
import { ShopifyPrices } from "./shopify-prices.ts";
import { BrowserSource } from "./browser-source.ts";
import { OperatingSystem } from "./operating-system.ts";

type State = {
	readonly defaultCountryCode: string;
	readonly pricingModel: OnceOffLoad<PricingModel>;
	readonly systemPalette: OnceOffLoad<Palette>;
	readonly frames: OnceOffLoad<readonly PictureFrame[]>;
	readonly hangingStrips: OnceOffLoad<HangingStrips | undefined>;
	readonly preassemblyOptions: OnceOffLoad<readonly PictureAddOnVariant[]>;
	readonly shopifyCollections: OnceOffLoad<
		readonly Pick<ShopifyCollection, "id" | "title">[]
	>;
	readonly isMobile: boolean;
	readonly picPricesByCountryCode: Record<string, ShopifyPrices>;
	readonly siteCode: string;
	readonly version: string;
	readonly browserSource: BrowserSource;
	readonly basePlateSizes: readonly BaseplateSize[];
	readonly os: OperatingSystem;
	readonly picToBrickCategories?: GetPicToBrickCategoriesQuery["picToBrickCategories"];
	readonly countries: OnceOffLoad<readonly Country[]>;
	readonly scenes: readonly Scene[];
	readonly maxNumberOfSelectableBasePlates: number;
	readonly shopifyShopDomain: string;
};

const initialState: State = {
	defaultCountryCode: "AU",
	siteCode: "au",
	pricingModel: pendingOnceOffLoad,
	systemPalette: pendingOnceOffLoad,
	frames: pendingOnceOffLoad,
	preassemblyOptions: pendingOnceOffLoad,
	shopifyCollections: pendingOnceOffLoad,
	hangingStrips: pendingOnceOffLoad,
	picPricesByCountryCode: {},
	version: "Unknown",
	isMobile: false,
	// TODO: Better option for def?
	shopifyShopDomain: "",
	os: "other",
	basePlateSizes: [24, 32],
	browserSource: "other",
	picToBrickCategories: undefined,
	countries: pendingOnceOffLoad,
	scenes: [],
	maxNumberOfSelectableBasePlates: maxNumberOfBasePlates,
};

export type { State };
export { initialState };
